import { Capacitor } from '@capacitor/core';
import { useConfig } from '@travelwin/core';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet-async';

import { useConsent } from '../../context/Consent';
import { useUser } from '../../context/User';
import { sendEvent } from '../../services/tiktok/TikTokService';

declare global {
  interface Window {
    ttq: any;
  }
}

export interface TikTokEvent {
  event_id: string;
  event_name: string;
  email?: string;
  currency?: string;
  price?: number;
  content_id?: string;
  content_name?: string;
  content_type?: string;
  brand?: string;
  payment_type?: string;
}

export const tikTokPixelAttributesToHeaders = () =>
  Object.entries({
    'X-TikTok-Ttp': Cookies.get('_ttp'),
    'X-TikTok-Ttclid': Cookies.get('ttclid'),
  }).reduce<Record<string, string>>((obj, [key, value]) => {
    if (value !== undefined) {
      obj[key] = value;
    }
    return obj;
  }, {});

const isNativePlatform = Capacitor.isNativePlatform();

export const trackTikTokPixel = async (event: TikTokEvent, server = true) => {
  if (typeof window !== 'undefined' && window.ttq) {
    const eventData = {
      ...(event.content_id !== undefined && {
        contents: [
          {
            content_id: event.content_id,
            content_name: event.content_name,
            content_type: event.content_type,
            price: event.price,
          },
        ],
      }),
      ...(event.price !== undefined && { value: event.price }),
      ...(event.currency !== undefined && {
        currency: event.currency.toUpperCase(),
      }),
      ...(event.payment_type !== undefined && {
        payment_type: event.payment_type,
      }),
    };
    window.ttq.track(event.event_name, eventData, { event_id: event.event_id });
  }
  if (server) {
    await sendEvent(event);
  }
};

const TikTokPixelHelmet = () => (
  <Helmet>
    <script>{` !(function (w, d, t) {
          w.TiktokAnalyticsObject = t;
          var ttq = (w[t] = w[t] || []);
          (ttq.methods = [
            'page',
            'track',
            'identify',
            'instances',
            'debug',
            'on',
            'off',
            'once',
            'ready',
            'alias',
            'group',
            'enableCookie',
            'disableCookie',
          ]),
            (ttq.setAndDefer = function (t, e) {
              t[e] = function () {
                t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              };
            });
          for (var i = 0; i < ttq.methods.length; i++)
            ttq.setAndDefer(ttq, ttq.methods[i]);
          (ttq.instance = function (t) {
            for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
              ttq.setAndDefer(e, ttq.methods[n]);
            return e;
          }),
            (ttq.load = function (e, n) {
              var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
              (ttq._i = ttq._i || {}),
                (ttq._i[e] = []),
                (ttq._i[e]._u = i),
                (ttq._t = ttq._t || {}),
                (ttq._t[e] = +new Date()),
                (ttq._o = ttq._o || {}),
                (ttq._o[e] = n || {});
              var o = document.createElement('script');
              (o.type = 'text/javascript'),
                (o.async = !0),
                (o.src = i + '?sdkid=' + e + '&lib=' + t);
              var a = document.getElementsByTagName('script')[0];
              a.parentNode.insertBefore(o, a);
            });
        })(window, document, 'ttq');`}</script>
  </Helmet>
);

export const TikTokPixelTracker: React.FC = () => {
  const { TIKTOK_PIXEL_CODE } = useConfig();
  const { consent } = useConsent();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    if (TIKTOK_PIXEL_CODE && !isNativePlatform) {
      if (window.ttq && consent.marketing) {
        window.ttq.load(TIKTOK_PIXEL_CODE);
        window.ttq.enableCookie();
        setInitialized(true);
      } else {
        window.ttq?.disableCookie();
        setInitialized(false);
      }
    }
  }, [TIKTOK_PIXEL_CODE, consent.marketing]);

  useEffect(() => {
    if (initialized) {
      trackTikTokPixel({
        event_id: uuidv4(),
        event_name: 'Pageview',
        email: user?.email.trim().toLowerCase(),
      });
    }
  }, [location.pathname, initialized, user?.email]);

  return <TikTokPixelHelmet />;
};

export default TikTokPixelTracker;
