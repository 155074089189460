import {
  TikTokEvent,
  tikTokPixelAttributesToHeaders,
} from '../../components/TikTokPixel';
import { request } from '../helpers/requests';

export const sendEvent = async (event: TikTokEvent): Promise<void> => {
  const options = {
    method: 'POST',
    headers: tikTokPixelAttributesToHeaders(),
    body: JSON.stringify(event),
  };
  return await request('ecommerce/tik_tok_event', options);
};
