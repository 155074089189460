import { Capacitor } from '@capacitor/core';
import { useConfig } from '@travelwin/core';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getCurrentLocale } from '../../i18n';

type ConsentContextType = {
  enabled: boolean;
  consent: {
    necessary: boolean;
    preferences: boolean;
    statistics: boolean;
    marketing: boolean;
  };
};

const defaultConsent = {
  enabled: false,
  consent: {
    necessary: false,
    preferences: false,
    statistics: false,
    marketing: false,
  },
};

const ConsentContext = createContext<ConsentContextType>(defaultConsent);

const extractConsent = () => {
  return {
    necessary: true,
    preferences: window.Cookiebot?.consent?.preferences === true,
    statistics: window.Cookiebot?.consent?.statistics === true,
    marketing: window.Cookiebot?.consent?.marketing === true,
  };
};

const Consent = ({ children }: { children: ReactNode }) => {
  const [consent, setConsent] =
    useState<ConsentContextType['consent']>(extractConsent());
  const [enabled, setEnabled] = useState(!!window.Cookiebot);
  const { COOKIEBOT_DOMAIN_GROUP_ID } = useConfig();

  useEffect(() => {
    if (!COOKIEBOT_DOMAIN_GROUP_ID || Capacitor.isNativePlatform()) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.async = true;
    script.setAttribute('id', 'Cookiebot');
    script.setAttribute('data-cbid', COOKIEBOT_DOMAIN_GROUP_ID);
    script.setAttribute('data-culture', getCurrentLocale());

    script.src = 'https://consent.cookiebot.com/uc.js';
    document.body.appendChild(script);

    return () => {
      script.remove();
    };
  }, [COOKIEBOT_DOMAIN_GROUP_ID]);

  // set default consent for gtag that will be later modified by cookiebot depending on user consent
  // more details: https://support.cookiebot.com/hc/en-us/articles/360003979074-Using-Google-Gtag-with-Cookiebot
  useEffect(() => {
    if (!COOKIEBOT_DOMAIN_GROUP_ID || Capacitor.isNativePlatform()) {
      return;
    }

    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      wait_for_update: 1000,
    });
    window.gtag('set', 'ads_data_redaction', true);
    window.gtag('set', 'url_passthrough', true);
  }, [COOKIEBOT_DOMAIN_GROUP_ID]);

  useEffect(() => {
    const handleConsentReady = () => {
      if (!window.Cookiebot?.consent) {
        return;
      }
      setEnabled(true);
      setConsent(extractConsent());
    };

    window.addEventListener('CookiebotOnConsentReady', handleConsentReady);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', handleConsentReady);
    };
  }, []);

  return (
    <ConsentContext.Provider value={{ consent, enabled }}>
      {children}
    </ConsentContext.Provider>
  );
};

const MockConsent = ({ children }: { children: ReactNode }) => {
  return (
    <ConsentContext.Provider value={defaultConsent}>
      {children}
    </ConsentContext.Provider>
  );
};

export const ConsentProvider = ({ children }: { children: ReactNode }) => {
  if (typeof window === 'undefined') {
    return <MockConsent>{children}</MockConsent>;
  } else {
    return <Consent>{children}</Consent>;
  }
};

export const useConsent = () => {
  const context = useContext(ConsentContext);

  if (context === undefined) {
    throw Error('useConsent must be used within a ConsentProvider');
  }

  return context;
};
